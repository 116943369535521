/**
 * Copyright Christian Kubitza
 * christian@ck3d.de
 * 2015-2020
 */

var SwitchableConsole = function(prefix, active, useLocalStorage, backgroundColor, textColor) {
	var sConsole = {};
	if (useLocalStorage) {	// to help debugging on mobile devices
		sConsole.active = active;
		sConsole.prefix = prefix;
		sConsole.log = function() {
			console.log.apply(this, ['['+prefix+']'].concat(Object.values(arguments)));
			if (typeof localStorage === "undefined") return;
			var lastIndex = parseInt(localStorage.getItem("maplog_lastindex"), 10);
			if (isNaN(lastIndex)) {
				lastIndex = -1;
			}
			lastIndex++;
			try {
				localStorage.setItem("maplog_"+lastIndex, JSON.stringify(Object.values(arguments)));
				localStorage.setItem("maplog_lastindex", lastIndex);
			} catch (e) {
				console.log("localStorageError");
			}
		};
		sConsole.error = function() {
			this.log.apply(this, ['ERROR:'].concat(arguments));
		};
		sConsole.warn = function() {
			this.log.apply(this, ['WARNING:'].concat(arguments));
		};
		sConsole.log("--- init console ---");
	} else {
		var consoleParent = (typeof window === 'object')? window : self;
		var consoleStylable = (!!consoleParent.chrome || !!(/firefox/i.test(navigator.userAgent)));

		for (var f in consoleParent.console) {
			if (typeof consoleParent.console[f] == 'function') {
				if (consoleStylable) {
					var style = 'background:'+(backgroundColor? backgroundColor : '#eeeeee')+'; color:'+(textColor? textColor : '#666666')+'; border-radius:3px; padding:1px 4px;';
					sConsole[f] = active? consoleParent.console[f].bind(consoleParent.console, '%c'+prefix, style) : function(){};
				} else {
					sConsole[f] = active? consoleParent.console[f].bind(consoleParent.console, '['+prefix+']') : function(){};
				}

			}
		}
	}
	return sConsole;
};

